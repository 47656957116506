import { required } from 'vuelidate/lib/validators'

export default {
  computed: {
    isFormValid() {
      return !this.$v.params.$anyError
    }
  },

  validations: {
    params: {
      fullName: {
        required
      }
    }
  }
}
